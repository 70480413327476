import withUseLoading from 'utilities/with-loading/withUseLoading';
import { sortBy } from 'lodash';
import { orderExamActions } from './order-exams.reducer';
import { searchExamProducts, getProductMetadata, validateOrderingFaculty, fetchPrograms, fetchOrderHistoryDetails } from '../../services/exam.service';
import { programTypeFields } from '../../constants/order-exams.constant';
import { appAsyncActions } from '../../../../redux/app';
import { getMessageBasedOnErrorType } from '../../../../utilities/error/error.utility';

export const checkAuthorizeOrderExams = () =>
  withUseLoading(async dispatch => {
    try {
      const { isAuthorized } = await validateOrderingFaculty();
      dispatch(orderExamActions.setAuthorizeOrderExams(isAuthorized));
    } catch (e) {
      dispatch(orderExamActions.setAuthorizeOrderExams(false));
      dispatch(appAsyncActions.setMessage(false, getMessageBasedOnErrorType(e)));
    }
  });

export const fetchProductMetadata = () =>
  withUseLoading(async dispatch => {
    try {
      const { programTypes } = await getProductMetadata();
      dispatch(orderExamActions.fetchProductMetadataSuccess(sortBy(programTypes, programTypeFields.programTypeName)));
    } catch (e) {
      dispatch(appAsyncActions.setMessage(false, getMessageBasedOnErrorType(e)));
    }
  });

export const fetchHESIProgramList = () =>
  withUseLoading(async dispatch => {
    try {
      const { programs } = await fetchPrograms();
      dispatch(orderExamActions.setPrograms(programs));
    } catch (e) {
      dispatch(appAsyncActions.setMessage(false, getMessageBasedOnErrorType(e)));
    }
  });

export const fetchExamProducts = (selectedProgramTypes = [], selectedAssessmentTypes = []) =>
  withUseLoading(async dispatch => {
    try {
      const response = await searchExamProducts(selectedProgramTypes, selectedAssessmentTypes);
      dispatch(orderExamActions.fetchExamProductsDataSuccess(response));
    } catch (e) {
      dispatch(appAsyncActions.setMessage(false, getMessageBasedOnErrorType(e)));
    }
  });

export const fetchOrderHistory = () =>
  withUseLoading(async dispatch => {
    try {
      const response = await fetchOrderHistoryDetails();
      const orderHistory = response && response.tspHesiExamPymtOrderHistories;
      dispatch(orderExamActions.fetchOrderHistoryDataSuccess(orderHistory));
    } catch (e) {
      dispatch(appAsyncActions.setMessage(false, getMessageBasedOnErrorType(e)));
    }
  });
