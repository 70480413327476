import { lazy } from 'react';
import { ALCAnalytics, ALCRoutePath } from '../constants/hesi.constants';

export const getRoutes = () => [
  {
    path: ALCRoutePath.orderHistory.orderHistory,
    component: lazy(() => import('../pages/order-history/OrderExamHistoryPage.Page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderHESIExams,
        pageName: 'alc-order-history'
      }
    }
  },
  {
    path: ALCRoutePath.orderHistory.orderHistoryDetails,
    component: lazy(() => import('../pages/order-history/OrderHistoryDetailsPage.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderHESIExams,
        pageName: 'alc-order-history-details'
      }
    }
  }
];

export const HESIOrderHistoryRoutes = {
  getRoutes
};
