import { ALCConstants } from '../constants/hesi.constants';
import { orderExamActions, orderExamReducer } from './order-exams';
import { StoreConfig } from '../../../redux/app.store';

const hesiStoreConfig : StoreConfig = {
  actionTypesToStore: [
    'orderExams/setProgramTypesSelected',
    'orderExams/setExamTypesSelected',
    'orderExams/setOrderItems',
    'orderExams/setOrderItemSelected',
    'orderExams/updateExamPagingOptions',
    'orderExams/setExamOrder',
    'orderExams/resetExamOrder',
    'orderExams/cleanUpExamOrderId'
  ],
  statesToBeStoredInLocalStorage: {
    [ALCConstants.redux.ORDER_EXAM_STATE]: orderExamActions.restoreOrderExamState
  },
  reducers: {
    [ALCConstants.redux.ORDER_EXAM_STATE]: orderExamReducer
  }
};

export default hesiStoreConfig;
