import { ELSCommonConfig } from '@els/els-ui-common-react';

export const HESIServerConstants = {
  DataSource: ELSCommonConfig.appProfile,

  common: {
    payment: {
      redirectURL: '/tsp/alc/order-hesi-exams/order-process/:tspHesiExamPymtOrderId/payment',
      cancelURL: '/tsp/alc/order-hesi-exams/order-process/:tspHesiExamPymtOrderId/cancel'
    },
    hesiBaseURL: 'https://hesi-test6.apps.els-ols.com'
  },
  local: {
    hesiBaseURL: 'https://hesi-dev.apps.els-ols.com'
  },
  dev: {
    hesiBaseURL: 'https://hesi-dev.apps.els-ols.com'
  },
  qa: {},
  test1: {},
  test2: {},
  test3: {},
  test4: {},
  test5: {},
  test6: {},
  stage: {
    hesiBaseURL: 'https://hesi-stage.apps.els-ols.com'
  },
  prod: {
    hesiBaseURL: 'https://hesi.elsevier.com'
  }
};
