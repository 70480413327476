import { ELSTokenServiceRegistrar, ELSTokenHelper } from '@els/els-ui-common-react';
import withUseLoading from 'utilities/with-loading/withUseLoading';
import * as securitySelectors from './security.selectors';
import { securityActions } from './security.reducer';
import { cookieServices, evolveServices, securityServices } from '../../services';
import {
  getEvolveLoginUrl,
  getEvolveLogoutUrl,
  getFedAccessLogoutUrl
} from '../../config/app.config';
import { clearTSPState, StorageHelper } from '../../helpers/storage.helper';
import { TokenHelper } from '../../helpers/token.helper';
import { navigateToExternalUrl } from '../../utilities/navigation/navigation.utility';

export const logout = () =>
  withUseLoading(async (dispatch) => {
    try {
      const isFaLogin = await cookieServices.isFaLogin();
      if (isFaLogin) {
        navigateToExternalUrl(getFedAccessLogoutUrl());
        return;
      }
      navigateToExternalUrl(getEvolveLogoutUrl());
    } catch (error) {
      ELSTokenServiceRegistrar.unRegister();
      clearTSPState();
      dispatch(securityActions.logoutFailed(error));
    }
  });

const handleAuthSuccess = async (dispatch) => {
  const sessionId = await cookieServices.getSessionId();
  const userRoles = await evolveServices.fetchUserRoles(sessionId);
  const eolsExternalUserId = StorageHelper.getEolsExternalUserId();
  const evolveUser = StorageHelper.getEvolveUser();
  evolveUser.userName = evolveUser.userName || StorageHelper.getUserName();
  evolveUser.roles = userRoles;
  dispatch(securityActions.authSuccess({
    token: ELSTokenHelper.getToken(),
    faLogin: evolveUser.faLogin,
    evolveUser,
    sessionId,
    eolsExternalUserId
  }));
};

const getAnonymousToken = async (dispatch) => {
  let anonymousToken = ELSTokenHelper.getToken();
  if (!anonymousToken) {
    const anonymousTokenObj = await securityServices.getAnonymousToken();
    if (anonymousTokenObj && anonymousTokenObj.token && anonymousTokenObj.token.token) {
      anonymousToken = anonymousTokenObj.token.token;
      ELSTokenServiceRegistrar.register(anonymousToken);
    }
  }
  dispatch(securityActions.setToken(anonymousToken));
};

const handleAuthFail = async (dispatch, redirectToLogin = true) => {
  clearTSPState();
  dispatch(securityActions.logoutSuccess());
  ELSTokenServiceRegistrar.unRegister();
  await getAnonymousToken(dispatch);
  if (redirectToLogin) {
    navigateToExternalUrl(getEvolveLoginUrl());
  }
};

const storeLoggedInInfo = (token: string, evolveUser, isLoggedIn: boolean, faLogin: boolean) => {
  ELSTokenServiceRegistrar.register(token);
  StorageHelper.storeLoggedIn(isLoggedIn);
  StorageHelper.storeEvolveUser({ ...evolveUser, faLogin });
};

export const checkFaLogin = async () => {
  try {
    const sessionId = await cookieServices.getSessionId();
    const paramSessionId = StorageHelper.getEvolveSessionId();
    const tokenExpired = TokenHelper.isTokenExpired();
    if (sessionId && (tokenExpired || (paramSessionId !== sessionId))) {
      const { token } = await securityServices.generatingJwtTokenBasedOnEvolveSessionId(true);
      const faLogin = await cookieServices.getFaLogin();
      const evolveUser = TokenHelper.getEvolveUser();
      storeLoggedInInfo(token, evolveUser, true, faLogin);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const singleSignOnUserLogin = async (token: string) => {
  const response = await securityServices.singleSignOn();
  ELSTokenServiceRegistrar.register(response.token.token);
  storeLoggedInInfo(token, response.existingUser, true, false);
};

export const authByEvolveSession = (redirectToLogin = true) =>
  withUseLoading(async (dispatch) => {
    try {
      ELSTokenServiceRegistrar.initializeFromReload();
      if (StorageHelper.isLoggedIn() && ELSTokenHelper.isTokenRegistered()) {
        await handleAuthSuccess(dispatch);
      } else if (await checkFaLogin()) {
        await handleAuthSuccess(dispatch);
      } else {
        await handleAuthFail(dispatch, redirectToLogin);
      }
    } catch (e) {
      await handleAuthFail(dispatch, redirectToLogin);
    }
  });

export const authByTokenParam = (token: string) =>
  withUseLoading(async (dispatch) => {
    try {
      await singleSignOnUserLogin(token);
      ELSTokenServiceRegistrar.initializeFromReload();
      await handleAuthSuccess(dispatch);
    } catch (e) {
      await handleAuthFail(dispatch, true);
    }
  });

export const handleTokenUpdated = () => async (dispatch, getState) => {
  try {
    const tspLoggedIn = securitySelectors.isLoggedIn(getState());
    const evolveLoggedIn = StorageHelper.isLoggedIn();
    if (tspLoggedIn === evolveLoggedIn) {
      return;
    }

    if (evolveLoggedIn) {
      dispatch(authByEvolveSession(false));
    } else {
      await handleAuthFail(dispatch, false);
    }
  } catch (e) {
    await handleAuthFail(dispatch, false);
  }
};

export const refreshSessionId = (sessionId, userRole, callback) => async (dispatch) => {
  try {
    await evolveServices.refreshSessionId(sessionId, userRole);
    if (typeof callback === 'function') {
      callback();
    }
  } catch (ex) {
    await handleAuthFail(dispatch, true);
  }
};
