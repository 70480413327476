import { isEqual, uniq, intersection, findIndex } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ALCConstants, defaultExamPagingOptions } from '../../constants/hesi.constants';
import { ProgramModel, PagingOptions, ProgramTypeModel } from '../../models/exam.model';
import { ExamOrderItemModel, ExamOrderModel } from '../../models/order.model';

export const REDUCER_ID = ALCConstants.redux.ORDER_EXAM_STATE;

const initialState = {
  isAuthorized: null,
  programTypes: null,
  examProducts: null,
  pagingOptions: defaultExamPagingOptions,
  orderItems: null,
  orderItemSelected: null,
  programs: [],
  examOrder: null,
  orderHistory: null,
};

export const orderExamSlice = createSlice({
  name: 'orderExams',
  initialState,
  reducers: {
    restoreOrderExamState(state, action) {
      return {
        ...state,
        ...action.payload,
        isAuthorized: null
      };
    },
    setAuthorizeOrderExams(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isAuthorized: action.payload
      };
    },
    fetchProductMetadataSuccess(state, action: PayloadAction<ProgramTypeModel[]>) {
      return {
        ...state,
        programTypes: action.payload
      };
    },
    setOrderItems(state, action: PayloadAction<ExamOrderItemModel[]>) {
      return {
        ...state,
        orderItems: action.payload
      };
    },
    updateOrderItems(state, action: PayloadAction<ExamOrderItemModel>) {
      let orderItems = state.orderItems && state.orderItems.length > 0 ? [...state.orderItems] : [];
      const index = findIndex(orderItems, { productId: action.payload.productId });
      if (index === -1) {
        orderItems.unshift(action.payload);
      } else {
        orderItems = orderItems.map(order => (order.productId === action.payload.productId ? { ...action.payload } : order));
      }
      return {
        ...state,
        orderItems
      };
    },
    setOrderItemSelected(state, action: PayloadAction<ExamOrderItemModel>) {
      return {
        ...state,
        orderItemSelected: action.payload
      };
    },
    setPrograms(state, action: PayloadAction<ProgramModel[]>) {
      return {
        ...state,
        programs: action.payload
      };
    },
    fetchExamProductsDataSuccess(state, action) {
      return {
        ...state,
        examProducts: action.payload
      };
    },
    updateExamPagingOptions(state, action: PayloadAction<PagingOptions>) {
      const pagingOptions = {
        ...(state.pagingOptions || {}),
        ...action.payload
      };

      const { selectedProgramTypes } = pagingOptions;
      const prevSelectedProgramTypes = (state.pagingOptions && state.pagingOptions.selectedProgramTypes) || [];
      const prevSelectedAssessmentTypes = (state.pagingOptions && state.pagingOptions.selectedAssessmentTypes) || [];
      const programTypes = state.programTypes && state.programTypes.length > 0 ? [...state.programTypes] : [];

      if (programTypes.length > 0 && !isEqual(selectedProgramTypes, prevSelectedProgramTypes) && prevSelectedAssessmentTypes.length > 0) {
        const filteredProgramTypes = programTypes.filter(type => selectedProgramTypes.includes(type.programTypeId));
        const examTypes = filteredProgramTypes.reduce((result, programType) => [...result, ...programType.examTypes], []);
        const assessmentTypeIds = uniq(examTypes.map(examType => examType.assessmentType.assessmentTypeId));

        const assessmentTypeIdData = intersection(assessmentTypeIds, prevSelectedAssessmentTypes);

        if (!isEqual(assessmentTypeIdData, prevSelectedAssessmentTypes)) {
          pagingOptions.selectedAssessmentTypes = assessmentTypeIdData;
        }
      }

      return {
        ...state,
        pagingOptions
      };
    },
    setExamOrder(state, action: PayloadAction<ExamOrderModel>) {
      return {
        ...state,
        examOrder: action.payload
      };
    },
    resetExamOrder(state) {
      return {
        ...state,
        pagingOptions: defaultExamPagingOptions,
        orderItems: null,
        orderItemSelected: null,
        examOrder: null
      };
    },
    cleanUpExamOrderId(state) {
      const newExamOrder = { ...state.examOrder };
      delete newExamOrder.tspHesiExamPymtOrderId;
      delete newExamOrder.contextId;
      delete newExamOrder.hesiExamOrderId;
      return {
        ...state,
        examOrder: {
          ...newExamOrder
        }
      };
    },
    fetchOrderHistoryDataSuccess(state, action) {
      return {
        ...state,
        orderHistory: action.payload
      };
    },
  }
});

export const orderExamActions = orderExamSlice.actions;
export const orderExamReducer = orderExamSlice.reducer;
