import { tspServiceApi } from 'helpers/RestClient.helper';
import { generatePath } from 'react-router';
import { buildExamOrderPayload } from '../utilities/order-exams/exam.utility';
import { getHostUrl } from '../../../config/app.config';
import { getHESIProperty } from '../configs/hesi.config';

type CreateOrderAPIResponse = Promise<{
  tspHesiExamPymtOrderId: number;
  hesiExamOrderId: string;
}>;

type CreatePaymentContextAPIResponse = { eCaptureURL: string; contextId: string };

type GetPaymentOrderDetailsAPIResponse = { paymentStatus: string };

export const createOrder = (paymentInformation, orderItems, paymentMethod) => {
  const data = buildExamOrderPayload(paymentInformation, orderItems, paymentMethod);
  return tspServiceApi.post('/hesi/alc/order', { data }) as CreateOrderAPIResponse;
};

export const deleteHesiExamPaymentOrder = (tspHesiExamPymtOrderId: number) => tspServiceApi.delete(`/hesi/alc/order/${tspHesiExamPymtOrderId}`);

export const createHesiExamPaymentContext = (tspHesiExamPymtOrderId: number) => {
  const hostUrl = getHostUrl();
  const paymentUrlObject = getHESIProperty('payment');
  const paymentData = {
    tspHesiExamPymtOrderId,
    redirectURL: hostUrl + generatePath(paymentUrlObject.redirectURL, { tspHesiExamPymtOrderId }),
    cancelURL: hostUrl + generatePath(paymentUrlObject.cancelURL, { tspHesiExamPymtOrderId })
  };

  return tspServiceApi.post('/hesi/alc/ecapture/payments', { data: paymentData }) as Promise<CreatePaymentContextAPIResponse>;
};

export const createHesiTaxAudit = (tspHesiExamPymtOrderId: number) => tspServiceApi.post(`/hesi/alc/order/tax/audit/${tspHesiExamPymtOrderId}`, { data: {} });

export const getPaymentOrderDetails = (tspHesiExamPymtOrderId: number, contextId: string) =>
  tspServiceApi.get(`/hesi/alc/ecapture/payments/${tspHesiExamPymtOrderId}/${contextId}`) as Promise<GetPaymentOrderDetailsAPIResponse>;

export const commitHesiTaxAudit = (tspHesiExamPymtOrderId: number) => tspServiceApi.post(`/hesi/alc/order/tax/commit/${tspHesiExamPymtOrderId}`, { data: {} });

export const abortHesiTaxAudit = (tspHesiExamPymtOrderId: number) => tspServiceApi.post(`/hesi/alc/order/tax/abort/${tspHesiExamPymtOrderId}`, { data: {} });
