import { PagingOptions } from '../models/exam.model';

export const ALCRoutePath = {
  rootPath: '/alc',
  orderExams: {
    home: '/alc/order-hesi-exams',
    programTypes: '/alc/order-hesi-exams/program-types',
    examTypes: '/alc/order-hesi-exams/exam-types',
    examCatalog: '/alc/order-hesi-exams/exam-catalog',
    orderCartReview: '/alc/order-hesi-exams/order-cart/review',
    orderCartPayment: '/alc/order-hesi-exams/order-cart/payment',
    orderCartConfirm: '/alc/order-hesi-exams/order-cart/confirm',
    orderProcessPayment: '/alc/order-hesi-exams/order-process/:tspHesiExamPymtOrderId/payment',
    orderProcessCancel: '/alc/order-hesi-exams/order-process/:tspHesiExamPymtOrderId/cancel'
  },
  orderHistory: {
    orderHistory: '/alc/order-hesi-exams/order-history',
    orderHistoryDetails: '/alc/order-hesi-exams/order-history-details/:tspHesiExamPymtOrderId'
  },
  error: {
    pageNotFound: '/alc/page-not-found',
    notAuthorized: '/alc/not-authorized'
  }
};

export const ALCConstants = {
  redux: {
    ORDER_EXAM_STATE: 'orderExamState'
  }
};
export const defaultExamPagingOptions: PagingOptions = {
  currentPage: 1,
  resultsPerPage: 25,
  sortBy: 'name',
  sortOrder: 'asc',
  query: '',
  selectedProgramTypes: [],
  selectedAssessmentTypes: []
};
export const ALCAnalytics = {
  pageType: {
    orderHESIExams: 'oe',
    examTypes: 'oe-et',
    examCatalog: 'oe-ec',
    orderCartReview: 'oe-oc',
    orderCartPayment: 'oe-oc',
    orderCartConfirm: 'oe-oc',
    orderProcessPayment: 'oe-op-p',
    orderProcessCancel: 'oe-op-c',
    notAuthorized: 'oe-na',
    pageNotFound: 'oe-nf'
  }
};

export const HESI_FILTER_TYPES = {
  PROGRAM_TYPE: 'programType',
  EXAM_TYPE: 'examType'
};
